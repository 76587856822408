<template>
    <div class="container">
        <div class="dashboard-default dashboard-main">
            <ejs-dashboardlayout ref="DashbordInstance" columns="5" rows="2" id='defaultLayout' :allowResizing="true" :cellSpacing="spacing">
            </ejs-dashboardlayout>
        </div>
    </div>
</template>
<script>
    // import headers from "../lib/headers.js"
    import { createApp } from "vue";
    import { DashboardLayoutComponent } from "@syncfusion/ej2-vue-layouts";
    import RessourceScheduler from "@/components/RessourceScheduler.vue";
    import router from '../router'

    export default {
        components: {
            'ejs-dashboardlayout': DashboardLayoutComponent
        },
        data() {
            return {
                token: localStorage.getItem('token'),
                error: false,
                errorMsg: '',
                spacing: [10, 10],
                scheduler: function () { return { template: createApp({}).component('ressourceScheduler', RessourceScheduler) } }
            }
        },
        watch: {
        },
        mounted: function () {
            if (!this.token)
                router.push('login');
            this.addPanel(0, 0, 0, 'header 0', '<div>p0</div>');
            this.addPanel(1, 0, 1, 'header 1', '<div>p1</div>');
            this.addPanel(2, 0, 2, 'header 2', '<div>p2</div>');
            this.addPanel(3, 1, 0, 'header 3', '<div>p3</div>');
            this.addPanel(4, 1, 1, 'header 4', '<div>p4</div>');
        },
        methods: {
            showError(err) {
                this.error = true
                this.errorMsg = err
            },
            addPanel: function (pid, row, col, header, content) {
                var panel = [{
                    'id': 'layout_' + pid.toString(), 'sizeX': 1, 'sizeY': 1, 'row': row, 'col': col,
                    //content: '<span id="close" class="e-template-icon e-clear-icon"></span><div class="e-panel-header">' + header + '</div><component v-bind:is="' + content + '"></component>'
                    header: '<span id="close" class= "e-template-icon e-clear-icon"> </span><div class="e-panel-header">' + header + '</div >',
                    content: content
                }];
                this.$refs.DashbordInstance.addPanel(panel[0]);
                var closeIcon = document.getElementById('layout_' + pid.toString()).querySelector('.e-clear-icon');
                closeIcon.addEventListener('click', this.onCloseIconHandler);
            },
            onCloseIconHandler: function (event) {
                if (event.target.offsetParent) {
                    this.$refs.DashbordInstance.removePanel(event.target.offsetParent.id);
                }
            }
        }
    }
</script>
<style>
    .container {
        width: 98%;
    }

    .dashboard-main {
        margin-top: 10px;
    }

    .headerText th {
        font-size: 1em;
    }

    .yellow tbody + tbody, .yellow td, .yellow th, .yellow thead th {
        background-color: lightgoldenrodyellow !important;
        color: darkslategrey !important;
    }

    .green tbody + tbody, .green td, .green th, .green thead th {
        background-color: green !important;
        color: white !important;
    }
    .e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-dl-icon {
        color:currentColor;
        font-size: 20px;
        height: 20px;
        width: 20px;
        bottom: 0px;
    }

    .dashboard-default #defaultLayout.e-dashboardlayout.e-control .e-panel:hover span {
        display: block;
    }

    .dashboard-default #defaultLayout .e-panel .e-panel-container .text-align {
        vertical-align: middle;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
    }

    .dashboard-default .e-template-icon {
        padding: 10px;
        float: right;
        display: none;
    }

    .dashboard-default .e-clear-icon::before {
        content: '\e7a7';
        font-size: 12px;
        font-family: 'e-icons';
    }

    .bootstrap4 .dashboard-default .e-clear-icon::before {
        content: '\e745';
    }

    .tailwind .dashboard-default .e-clear-icon::before, .tailwind-dark .dashboard-default .e-clear-icon::before {
        content: '\e771';
        font-size: 16px;
    }

    .bootstrap5 .dashboard-default .e-clear-icon::before, .bootstrap5-dark .dashboard-default .e-clear-icon::before {
        content: '\e7e7';
        font-size: 16px;
    }

    .dashboard-default .text-align {
        line-height: 160px;
    }

    .dashboard-default .e-clear-icon {
        position: absolute;
        right: 0;
        cursor: pointer;
    }

    .dashboard-default .e-panel {
        overflow:scroll;
    }

    body.tailwind-dark .e-dashboardlayout.e-control .e-panel {
        border: 1px solid #d7d7d7;
    }
</style>
