<template>
    <div class="col-lg-12 control-section color-appbar-section">
        <div class="appbar-sample">
            <div class="color-appbar-container">
                <div class="col-md-12">
                    <ejs-appbar :colorMode="actColorMode" isSticky="true">
                        <ejs-button v-on:click="onDashboard" cssClass="e-inherit e-appbar-menu">Startseite</ejs-button>
                        <ejs-dropdownbutton :select="onSelect" :cssClass="'e-inherit e-appbar-menu ' + appBarColor.colorClass" :items="locationDropDownButtonItems">Ihre Daten</ejs-dropdownbutton>
                        <ejs-button :select="onSelect" :cssClass="'e-inherit e-appbar-menu ' + appBarColor.colorClass">KFZ-Aktion</ejs-button>
                        <ejs-dropdownbutton :select="onSelect" :cssClass="'e-inherit e-appbar-menu ' + appBarColor.colorClass" :items="messageDropDownButtonItems">Nachrichten</ejs-dropdownbutton>
                        <ejs-dropdownbutton :select="onSelect" :cssClass="'e-inherit e-appbar-menu ' + appBarColor.colorClass" :items="optionDropDownButtonItems">Optionen</ejs-dropdownbutton>
                        <ejs-dropdownbutton :select="onSelect" :cssClass="'e-inherit e-appbar-menu ' + appBarColor.colorClass" :items="viewDropDownButtonItems">Ansicht</ejs-dropdownbutton>
                        <div class="e-appbar-spacer"></div>
                        <div style="width: 200px; margin-right:10px">
                            <span class="e-input-group e-control-wrapper e-inherit">
                                <input type="text" class="e-searchinput e-input" placeholder="Suchen" />
                                <span class="e-icons e-search e-input-group-icon"></span>
                            </span>
                        </div>
                        <div class="e-appbar-separator"></div>
                        <ejs-tooltip ref="tooltip" position="Bottom Center" :content="userInformation">
                            <ejs-dropdownbutton :select="onSelect" :cssClass="'e-inherit e-appbar-menu ' + appBarColor.colorClass" :items='userMenuItems'>
                                <div id="userContext" class="e-avatar e-avatar-small e-avatar-circle"><img :src="userInitial" /></div>
                            </ejs-dropdownbutton>
                        </ejs-tooltip>
                        <div class="e-appbar-separator"></div>
                        <ejs-button :isPrimary='appBarColor.isPrimary' :cssClass='appBarColor.loginClass' v-on:click="logout">Abmelden</ejs-button>
                    </ejs-appbar>
                    <div>&nbsp;</div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    @import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
    @import "../../node_modules/@syncfusion/ej2-layouts/styles/material.css";

    .color-appbar-section .e-dropdown-btn.e-inherit,
    .color-appbar-section .e-btn.e-inherit.home {
        margin: 0 5px;
    }

    .color-appbar-section .e-appbar-icon-menu {
        display: none;
    }

    .color-appbar-section .e-appbar .e-appbar-separator {
        margin: 0 10px;
    }

    @media screen and (max-width: 480px) {

        .color-appbar-section .e-appbar-icon-menu {
            display: block;
        }

        .color-appbar-section .login,
        .color-appbar-section .e-appbar-separator,
        .color-appbar-section .e-dropdown-btn.e-inherit.e-appbar-menu,
        .color-appbar-section .e-menu-wrapper.e-inherit.e-appbar-menu,
        .color-appbar-section .e-menu-wrapper.e-inherit.e-appbar-icon-menu .e-menu .e-menu-item .e-caret,
        .color-appbar-section .e-btn.e-inherit.e-appbar-menu {
            display: none;
        }

        .color-appbar-section .e-menu-wrapper.e-inherit.e-appbar-icon-menu ul .e-menu-item.e-menu-caret-icon {
            padding: 0 8px;
        }

        .color-appbar-section .e-menu-wrapper.e-inherit ul .e-menu-item .e-menu-icon {
            margin-right: 0;
        }

        .color-appbar-section .e-menu-wrapper.e-inherit {
            margin: 0 10px;
        }

        .fabric .color-appbar-section .e-menu-wrapper.e-inherit ul.e-menu:not(.e-vertical),
        .fabric-dark .color-appbar-section .e-menu-wrapper.e-inherit ul.e-menu:not(.e-vertical) {
            padding: 0;
        }

        .color-appbar-section .e-input-group.e-control-wrapper.e-inherit {
            margin-left: 10px;
        }

        .color-appbar-section .menu {
            margin-bottom: 3px;
        }

        .color-appbar-section .color-appbar-container {
            margin: 0 -30px;
        }
    }
</style>

<script>
    import { AppBarComponent } from "@syncfusion/ej2-vue-navigations";
    import { DropDownButtonComponent } from '@syncfusion/ej2-vue-splitbuttons';
    import { ButtonComponent } from '@syncfusion/ej2-vue-buttons';
    import { TooltipComponent } from "@syncfusion/ej2-vue-popups";
    import router from '../router'

    export default {
        components: {
            'ejs-appbar': AppBarComponent,
            'ejs-button': ButtonComponent,
            'ejs-dropdownbutton': DropDownButtonComponent,
            'ejs-tooltip': TooltipComponent
        },
        data: function () {
            return {
                userInitial: '',
                userInformation: '',
                locationDropDownButtonItems: [
                    { text: 'Adresse', run: function () { alert("l1"); } },
                    { text: 'Details', run: function () { alert("l2"); } },
                    { text: 'Risiken und Verschiedenes', run: function () { alert("l3"); } },
                ],
                messageDropDownButtonItems: [
                    { text: 'Nachrichten', run: function () { alert("m1"); } },
                    { text: 'Gruppennachrichten', run: function () { alert("m2"); } },
                    { text: 'Dokumente', run: function () { alert("m3"); } },
                ],
                viewDropDownButtonItems: [],
                optionDropDownButtonItems: [
                    { text: 'ph', run: function () { alert("o1"); } },
                ],
                appBarColors: [
                    { colorMode: 'Light', colorClass: 'e-light', isPrimary: 'true', loginClass: 'login', name: 'Hell' },
                    { colorMode: 'Dark', colorClass: 'e-dark', isPrimary: 'false', loginClass: 'e-inherit login', name: 'Dunkel' },
                    { colorMode: 'Primary', colorClass: 'e-primary', isPrimary: 'false', loginClass: 'e-inherit login', name: 'Blau' },
                    { colorMode: 'Inherit', colorClass: 'e-inherit', isPrimary: 'true', loginClass: 'login', name: 'Hintergrund' }
                ],
                appBarColor: { colorMode: 'Light', colorClass: 'e-light', isPrimary: 'true', loginClass: 'login' },
                actColorMode: 'Light',
                userMenuItems: [
                    { text: 'Profil ändern', run: function () { router.push('editProfile'); } },
                    { text: 'Password ändern', run: function () { router.push('passwordManagement'); } }
                ]
            }
        },
        mounted: function () {
            try {
                this.userInitial = localStorage.getItem('initial') ? localStorage.getItem('initial') : this.generateAvatar(localStorage.getItem('firstname').charAt(0) + localStorage.getItem('lastname').charAt(0));
                this.userInformation = localStorage.getItem('firstname') + ' ' + localStorage.getItem('lastname') + '<br/>' + localStorage.getItem('email') + '<br/>E-Mailadresse ist ' + (localStorage.getItem('confirmed') ? '' : 'nicht ') + 'bestätigt.';
            } catch {
                this.userInitial = 'Please log in';
            }
            this.appBarColor = this.appBarColors[0];
            var selv = this;
            this.viewDropDownButtonItems = this.appBarColors.map((p) => ({ text: p.name, run: function () { selv.actColorMode = p.colorMode; } }));
            let inputElement = document.querySelectorAll('.color-appbar-section .e-input-group .e-input');

            for (let i = 0; i < inputElement.length; i++) {
                inputElement[i].addEventListener("focus", function () {
                    let parentElement = this.parentNode;
                    if (parentElement.classList.contains('e-input-in-wrap')) {
                        parentElement.parentNode.classList.add('e-input-focus');
                    } else {
                        this.parentNode.classList.add('e-input-focus');
                    }
                });
                inputElement[i].addEventListener("blur", function () {
                    let parentElement = this.parentNode;
                    if (parentElement.classList.contains('e-input-in-wrap')) {
                        parentElement.parentNode.classList.remove('e-input-focus');
                    } else {
                        this.parentNode.classList.remove('e-input-focus');
                    }
                });
            }
        },
        methods: {
            beforeItemRender: function (args) {
                if (args.element.children.length > 0 && args.element.children[0].classList.contains("e-more-vertical-1")) {
                    args.element.setAttribute('aria-label', 'more vertical');
                }
            },
            onCreate: function () {
                let menuButtonElement = document.querySelectorAll('.color-appbar-section .e-inherit.menu');
                for (let j = 0; j < menuButtonElement.length; j++) {
                    if (!(menuButtonElement[j].hasAttribute("aria-label"))) {
                        menuButtonElement[j].setAttribute('aria-label', 'menu');
                    }
                }
            },
            onSelect: function (e) {
                e.item.run();
            },
            onDashboard: function () {
                alert("DB");
            },
            logout: function () {
                localStorage.clear();
                router.push('login');
            },
            generateAvatar: function (text) {
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");

                const color = [
                    "#5050ff",
                    "#50ff50",
                    "#ff5050",
                    "#ff5000",
                    "#ff0050",
                    "#0050ff",
                    "#00ff50",
                    "#50ff00",
                    "#5000ff",
                ];

               var random = Math.floor(Math.random() * color.length);

                canvas.width = 200;
                canvas.height = 200;

                // Draw background
                context.fillStyle = `${color[random]}80`;
                context.fillRect(0, 0, canvas.width, canvas.height);

                // Draw text
                context.font = "bold 80px Arial";
                context.fillStyle = 'white';
                context.textAlign = "center";
                context.textBaseline = "middle";
                context.fillText(text, canvas.width / 2, canvas.height / 2);

                var data = canvas.toDataURL("image/png");

                localStorage.setItem("initial", data);

                return data;
            }
        }
    }
</script>
